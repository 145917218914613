'use strict';

angular.module('risevision.schedules.services')
  .factory('scheduleFactory', ['$modal',
    function ($modal) {
      var factory = {};

      factory.openPlaylistModal = function (playlistItem) {
        $modal.open({
          templateUrl: 'partials/schedules/playlist-item.html',
          controller: 'playlistItemModal',
          size: 'md',
          resolve: {
            playlistItem: function () {
              return playlistItem;
            }
          }
        });
      };

      return factory;
    }
  ]);
