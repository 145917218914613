(function () {
  'use strict';
  /*jshint camelcase: false */

  angular.module('risevision.store.services')
    .service('storeService', ['$q', '$log', '$http', 'storeAPILoader',
      'coreAPILoader',
      function ($q, $log, $http, storeAPILoader, coreAPILoader) {

        var _getResult = function (resp) {
          if (resp.result !== null && typeof resp.result === 'object') {
            return resp.result;
          } else {
            return resp;
          }
        };

        var service = {
          validateAddress: function (addressObject) {
            var obj = {
              'street': addressObject.street,
              'unit': addressObject.unit,
              'city': addressObject.city,
              'country': addressObject.country,
              'postalCode': addressObject.postalCode,
              'province': addressObject.province,
            };

            return storeAPILoader()
              .then(function (storeApi) {
                return storeApi.company.validateAddress(obj);
              })
              .then(function (resp) {
                var result = _getResult(resp);
                $log.debug('validateAddress result: ', result);

                if (result.code !== -1) {
                  return $q.resolve(result);
                } else {
                  return $q.reject(result);
                }
              });
          },
          estimatePurchase: function (jsonData) {
            var deferred = $q.defer();
            var obj = {
              jsonData
            };

            storeAPILoader().then(function (storeApi) {
                return storeApi.purchase.estimate(obj);
              })
              .then(function (resp) {
                if (resp.result && !resp.result.error && resp.result.items) {
                  $log.debug('tax estimate resp', resp);
                  deferred.resolve(resp.result);
                } else {
                  console.error('Failed to get tax estimate.', resp.result);

                  deferred.reject(resp.result);
                }
              })
              .then(null, function (resp) {
                console.error('Failed to get tax estimate.', resp);

                deferred.reject(resp && resp.result && resp.result.error);
              });
            return deferred.promise;
          },
          preparePurchase: function (jsonData) {
            var deferred = $q.defer();
            storeAPILoader().then(function (storeAPI) {
                var obj = {
                  jsonData: jsonData
                };
                return storeAPI.purchase.prepare(obj);
              })
              .then(function (resp) {
                if (resp && resp.result && !resp.result.error) {
                  $log.debug('prepare purchase resp', resp);
                  deferred.resolve(resp.result);
                } else {
                  deferred.reject(resp && resp.result && resp.result.error);
                }
              })
              .then(null, function (resp) {
                console.error('Failed to prepare Purchase.', resp);

                deferred.reject(resp && resp.result && resp.result.error);
              });
            return deferred.promise;
          },
          purchase: function (jsonData) {
            var deferred = $q.defer();
            storeAPILoader().then(function (storeAPI) {
                var obj = {
                  jsonData: jsonData
                };
                return storeAPI.purchase.put(obj);
              })
              .then(function (resp) {
                if (resp && resp.result && !resp.result.error) {
                  $log.debug('purchase resp', resp);
                  deferred.resolve(resp.result);
                } else {
                  deferred.reject(resp && resp.result && resp.result.error);
                }
              })
              .then(null, function (resp) {
                console.error('Failed to get Purchase.', resp);

                deferred.reject(resp && resp.result && resp.result.error);
              });
            return deferred.promise;
          },
          preparePayment: function (paymentMethodId, invoiceId, companyId, token) {
            var deferred = $q.defer();
            storeAPILoader().then(function (storeAPI) {
                var obj = {
                  paymentMethodId: paymentMethodId,
                  invoiceId: invoiceId,
                  companyId: companyId,
                  token: token
                };
                return storeAPI.payment.prepare(obj);
              })
              .then(function (resp) {
                if (resp && resp.result && !resp.result.error) {
                  $log.debug('prepare payment resp', resp);
                  deferred.resolve(resp.result);
                } else {
                  deferred.reject(resp && resp.result && resp.result.error);
                }
              })
              .then(null, function (resp) {
                console.error('Failed to prepare Payment.', resp);

                deferred.reject(resp && resp.result && resp.result.error);
              });
            return deferred.promise;
          },
          collectPayment: function (paymentIntentId, invoiceId, companyId, token) {
            var deferred = $q.defer();
            storeAPILoader().then(function (storeAPI) {
                var obj = {
                  paymentIntentId: paymentIntentId,
                  invoiceId: invoiceId,
                  companyId: companyId,
                  token: token
                };
                return storeAPI.payment.collect(obj);
              })
              .then(function (resp) {
                if (resp && resp.result && !resp.result.error) {
                  $log.debug('purchase resp', resp);
                  deferred.resolve(resp.result);
                } else {
                  deferred.reject(resp && resp.result && resp.result.error);
                }
              })
              .then(null, function (resp) {
                console.error('Failed to get Purchase.', resp);

                deferred.reject(resp && resp.result && resp.result.error);
              });
            return deferred.promise;
          },
          addTaxExemption: function (companyId, taxExemption, blobKey) {
            var deferred = $q.defer();
            // var expiryDateString = $filter('date')(taxExemption.expiryDate, 'yyyy-MM-dd');

            coreAPILoader().then(function (coreAPI) {
              var obj = {
                'companyId': companyId,
                // 'country': taxExemption.country,
                // 'state': taxExemption.province,
                'blobKey': blobKey,
                'number': taxExemption.number,
                // 'expiryDate': expiryDateString
              };
              var request = coreAPI.taxExemption.add(obj);
              request.execute(function (resp) {
                if (resp.error) {
                  $log.error('Error adding tax exemption: ', resp.message);
                  deferred.reject(resp.error);
                } else {
                  deferred.resolve(resp);
                }
              });
            });
            return deferred.promise;
          },
          uploadTaxExemptionCertificate: function (file) {
            var deferred = $q.defer();

            var formData = new FormData();

            formData.append('file', file);

            coreAPILoader().then(function (coreAPI) {
              var request = coreAPI.taxExemption.getUploadUrl();
              request.execute(function (resp) {
                if (resp.error) {
                  $log.error('Error getting upload url: ', resp.message);
                  deferred.reject(resp.error);
                } else {
                  $http.post(resp.result.item, formData, {
                      withCredentials: true,
                      headers: {
                        'Content-Type': undefined
                      },
                      transformRequest: angular.identity
                    })
                    .then(function (response) {
                        deferred.resolve(response.data);
                      },
                      function (error) {
                        $log.error('Error uploading file: ', error);
                        deferred.reject(error);
                      });
                }
              });
            });
            return deferred.promise;
          }
        };

        return service;
      }
    ]);
})();
