'use strict';

angular.module('risevision.schedules.services', [
  'risevision.common.header',
  'risevision.common.gapi',
  'risevision.apps.services',
  'risevision.template-editor.services',
  'risevision.widget.common.url-field.insecure-url'
]);
angular.module('risevision.schedules.controllers', []);
